<template>
  <div @click="error = null" v-if="caseData">
    <p class="text-xs-center">
      {{ $config('translates.checkYourData') }}
    </p>

    <v-layout align-center justify-center class="mt-4">
      <v-flex xs12 sm12 md11 lg7>
        <v-layout row>
          <v-flex xs6>
            <v-subheader class="red--text">
              {{ $config('translates.caseNumberFull') }}:
            </v-subheader>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              class="pt-2"
              :value="caseData.ID + '' + caseData.CheckDigit"
              hide-details
              full-width
              disabled
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6>
            <v-subheader class="red--text">
              {{ $config('translates.fioPart') }}:
            </v-subheader>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              class="pt-2"
              :value="caseData.FullName"
              hide-details
              full-width
              disabled
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6>
            <v-subheader class="red--text">
              {{ $config('translates.companyDebt') }}:
            </v-subheader>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              class="pt-2"
              :value="caseData.ContragentName"
              hide-details
              full-width
              disabled
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs6>
            <v-subheader class="red--text">
              {{ $config('translates.debt') }}:
            </v-subheader>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              class="pt-2"
              :value="
                currencyFilterWithDecimals(+caseData.ActualAmount) +
                '   ' +
                caseData.Currency
              "
              hide-details
              full-width
              disabled
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row class="mt-8 mb-3">
          <v-flex xs2>
            <v-btn text @click.native="$emit('cancel')" large>{{
              $config('translates.back')
            }}</v-btn>
          </v-flex>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click.native="$emit('confirm')" large>{{
            $config('translates.next')
          }}</v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    caseData: Object,
  },
  methods: {
    currencyFilter(value) {
      return this.$options.filters.currency(value, '', 2, {
        thousandsSeparator: ' ',
        decimalSeparator: '.',
      });
    },

    currencyFilterWithDecimals(value) {
      return this.currencyFilter(value);
    },
  },
};
</script>

<style lang="scss"></style>
