import { get } from 'lodash';
// eslint-disable-next-line unicorn/prefer-module
let configData = require('../config.json');

export const config = {
  install(Vue) {
    Vue.prototype.$config = (path, defaultValue = null) => {
      return get(configData, path, defaultValue);
    };
  },
};

export const getConfig = (path, defaultValue = null) => {
  return get(configData, path, defaultValue);
};
