<template>
  <div @click="error = null" v-if="caseData">
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md11 lg7>
        <div class="pl-3">
          <p>
            {{ $config('translates.setSumForPay') }}
            <span v-if="!isRsv">{{ $config('translates.getAttention') }}</span>
            <b
              >{{ $config('translates.totalDebtSum') }}
              {{
                currencyFilterWithDecimals(caseData.ActualAmount) +
                ' ' +
                caseData.Currency
              }}.</b
            >
          </p>
        </div>
        <v-layout row>
          <v-flex xs5>
            <v-subheader class="red--text">
              {{ $config('translates.sumForPay') }}:
            </v-subheader>
          </v-flex>
          <v-flex xs7 sm7>
            <v-text-field
              ref="paySumInput"
              style="max-width: 200px"
              class="pt-2 pl-3 pr-3 input-group--focused"
              v-model="PaySum"
              name="PaySum"
              :rules="PaySumRules"
              :placeholder="this.$config('translates.typeSum')"
              :suffix="caseData.Currency"
              v-money="{}"
            ></v-text-field>
            <small class="pl-3" style="display: inline-block">
              {{ $config('translates.sumWillChargeFromInvoice') }}
            </small>
            <v-spacer class="mt-4"></v-spacer>
            <v-btn v-if="isRsv" @click="startPay" color="primary">{{
              $config('translates.toPay')
            }}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-spacer class="mt-4"></v-spacer>
    <v-tabs show-arrows v-model="activeTab">
      <v-layout align-center justify-center class="mt-2">
        <v-flex xs12 sm12 md11 lg7>
          <v-layout row class>
            <v-flex xs5>
              <v-subheader class="red&#45;&#45;text uppercase">
                {{ $config('translates.paymentSystem') }}
              </v-subheader>
            </v-flex>
            <v-flex xs7 class="pt-2 pr-3 pl-3">
              <v-tab v-for="provider in activeProviders" :key="provider.ID">
                {{ provider.ProviderName }}
              </v-tab>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-tab-item
        v-for="provider in caseData.PaymentProvider"
        :key="provider.ID"
      >
        <v-form
          v-model="valid"
          :ref="'form_' + provider.ID"
          @submit.prevent="goToProvider(provider)"
        >
          <v-layout align-center justify-center class="mt-1">
            <v-flex xs12 sm12 md11 lg7>
              <v-spacer class="mt-4"></v-spacer>
              <v-layout row align-center justify-center class="mt-4">
                <img :src="provider.LogoUrl" height="30" />
              </v-layout>
              <v-spacer class="mt-4"></v-spacer>
              <v-layout row>
                <v-flex xs5>
                  <v-subheader class="red&#45;&#45;text uppercase">
                    {{ $config('translates.commission') }}
                  </v-subheader>
                </v-flex>
                <v-flex xs7>
                  <v-text-field
                    class="pt-2"
                    :value="
                      currencyFilter(CommissionSum(provider)) +
                      ' ' +
                      caseData.Currency
                    "
                    hide-details
                    name="Commission"
                    disabled
                    full-width
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex xs5>
                  <v-subheader class="red&#45;&#45;text uppercase">{{
                    $config('translates.totalToPay')
                  }}</v-subheader>
                </v-flex>
                <v-flex xs7>
                  <v-text-field
                    full-width
                    hide-details
                    class="pt-2"
                    :value="
                      currencyFilter(+PaySumInt + CommissionSum(provider)) +
                      ' ' +
                      caseData.Currency
                    "
                    disabled
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row class="mt-8 mb-3">
                <v-btn
                  :disabled="loading"
                  text
                  @click.native="$emit('cancel')"
                  large
                >
                  Назад
                </v-btn>

                <v-spacer></v-spacer>
                <v-btn
                  :loading="loading"
                  color="primary"
                  :class="{ displayedBtn: displayedBtnRbk }"
                  @click.native="goToProvider(provider)"
                  large
                >
                  <template
                    v-if="provider.ProviderName === 'RBK Money Finance'"
                  >
                    Запрос данных для платежа
                  </template>
                  <template v-else>
                    Перейти на сайт {{ provider.ProviderName }}
                  </template>
                </v-btn>

                <component
                  class="initButton__38077"
                  ref="modal"
                  v-if="modal"
                  :is="modal"
                ></component>
              </v-layout>

              <v-layout row align-center justify-center class="mb-4">
                <v-flex xs12 sm12 md11 lg7class="mt-3 mb-1">
                  <small>
                    {{ $config('translates.cameOnPage') }}
                    {{ provider.ProviderName | uppercase }}
                    {{ $config('translates.pressButton') }}
                    {{ provider.ProviderName | uppercase }}"
                    {{ $config('translates.agree') }}
                    <template v-if="!provider.LicenseUrl">
                      {{ $config('translates.terms') }}
                    </template>
                    <a :href="provider.LicenseUrl" target="_blank" v-else>
                      {{ $config('translates.terms') }}
                    </a>
                  </small>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-tab-item>
    </v-tabs>
    <v-alert
      prominent
      text
      type="warning"
      icon="warning"
      :value="!!successPayment"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ $config('translates.youNeedSaveCopy') }}
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="successPayment = false">{{
            $config('translates.ok')
          }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import { getCasePaymentLink } from '../api';
import rbkModal from '../custom/rbkform.vue';
import Vue from 'vue';

export default {
  props: {
    caseData: Object,
  },
  // eslint-disable-next-line vue/no-unused-components
  components: { rbkModal },
  data() {
    return {
      // showIframe: false,
      modal: false,
      valid: true,
      displayedBtnRbk: false,
      loading: false,
      PaySum: null,
      activeTab: null,
      PaySumRules: [
        () =>
          this.PaySumInt >= this.MinAmountToPay ||
          `${this.$config(
            'translates.minAmount',
          )} ${this.currencyFilterWithDecimals(this.MinAmountToPay)} ${
            this.caseData.Currency
          }`,
        () =>
          this.PaySumInt < 1_000_000 ||
          this.$config('translates.incorrectAmount'),
      ],
      successPayment: false,
    };
  },

  created() {
    this.PaySum = Math.ceil(+this.caseData.ActualAmount);
  },

  watch: {
    PaySum() {
      this.$nextTick(() => {
        this.valid = this.$refs.paySumInput.valid;
      });
    },
  },

  methods: {
    startPay() {
      // this.showIframe = true
      if (window.cp && window.cp.CloudPayments) {
        const widget = new window.cp.CloudPayments();
        widget.pay(
          'charge', // или 'auth'
          {
            //options
            publicId: this.$config('cloudPaymentPublicId'), //id из личного кабинета
            description: this.$config('cloudPaymentDescription'), //назначение
            amount: +this.PaySum.replace(/\s/g, ''), //сумма
            accountId: this.caseData.ID,
            currency: this.caseData.Currency, //валюта
            skin: 'mini', //дизайн виджета (необязательно)
          },
          {
            onSuccess: () => {
              // success
              this.successPayment = true;
              this.PaySum = null;
            },
          },
        );
      } else {
        Vue.loadScript(
          'https://widget.cloudpayments.ru/bundles/cloudpayments',
        ).then(() => {
          this.startPay();
        });
      }
    },
    goToProvider(provider) {
      if (this.loading) return;
      if (!this.$refs.paySumInput.validate()) return;

      this.loading = true;

      getCasePaymentLink({
        token: this.caseData.token,
        amount: this.PaySumInt,
        providerID: provider.ID,
      })
        .then((response) => {
          if (response.data.isModal) {
            this.openModal(response.data);
          } else {
            this.redirectPost(response.data);
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          location.href = '/';
        });
    },

    openModal(params) {
      this.displayedBtnRbk = true;
      // eslint-disable-next-line unicorn/prefer-module
      let component = require('../custom/rbkform.vue').default;
      this.modal = {
        render: (h) =>
          h(component, {
            props: {
              ...params.data,
              url: params.callBackUrl,
            },
          }),
      };
      this.clickButton();
    },

    clickButton() {
      this.$nextTick(() => {
        let interval = setInterval(() => {
          let button = this.$refs.modal[0].$el.querySelectorAll('button')[0];
          if (button) {
            clearInterval(interval);
            button.className = 'btn btn--large primary';
            button.innerHTML =
              '<div class="btn__content" style="background-color: #ffc00a;">' +
              this.$config('translates.payment') +
              '</div>';
            button.style.width = '353px';
            button.style.fontWeight = '600';
            button.parentElement.style.display = 'block';
          }
        }, 200);
      });
    },

    currencyFilter(value) {
      return this.$options.filters.currency(value, '', 0, {
        thousandsSeparator: ' ',
        decimalSeparator: '.',
      });
    },

    currencyFilterWithDecimals(value) {
      return this.$options.filters.currency(value, '', 2, {
        thousandsSeparator: ' ',
        decimalSeparator: '.',
      });
    },

    CommissionSum(provider) {
      return Math.ceil(this.PaySumInt * provider.Commission);
    },

    redirectPost({ url, data, method = 'post' }) {
      let form = document.createElement('form');
      document.body.append(form);
      form.method = method;
      form.action = url;
      for (let name in data) {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = data[name];
        form.append(input);
      }
      form.submit();
    },
  },

  computed: {
    isRsv() {
      return this.$config('title').toString() === 'PCB'.toString();
    },
    activeProviders() {
      return this.caseData.PaymentProvider.filter((provider) => {
        return provider.ProviderName === 'Portmone';
      });
    },
    currentProvider() {
      return this.caseData.PaymentProvider[+this.activeTab];
    },
    MinAmountToPay() {
      return this.currentProvider.MinAmountToPay || 1;
    },
    PaySumInt() {
      return ('' + this.PaySum).replace(/\s/g, '');
    },
  },
};
</script>
<style scoped>
.uppercase {
  text-transform: uppercase;
}
.displayedBtn {
  display: none;
}
.initButton__38077 {
  padding: 0 0 !important;
  border: 0 solid #2384c1 !important;
  background-image: none !important;
  box-shadow: none !important;
}
</style>
