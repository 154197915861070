<template>
  <form :action="url" method="GET" style="display: none">
    <component
      :is="'script'"
      src="https://checkout.rbk.money/checkout.js"
      class="rbkmoney-checkout"
      :data-invoice-id="invoiceId"
      :data-invoice-access-token="invoiceAccessToken"
      :data-payment-flow-hold="paymentFlowHold"
      :data-hold-expiration="holdExpiration"
      :data-name="name"
      :data-logo="logo"
      :data-label="label"
      :data-description="description"
      :data-pay-button-label="payButtonLabel"
    >
    </component>
  </form>
</template>

<script>
export default {
  props: [
    'invoiceId',
    'email',
    'description',
    'url',
    'holdExpiration',
    'invoiceAccessToken',
    'label',
    'logo',
    'name',
    'payButtonLabel',
    'paymentFlowHold',
  ],
};
</script>
