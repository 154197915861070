<template>
  <div @click="error = null">
    <p class="text-xs-center">
      {{ $config('translates.typeYourRegistrationNumber')
      }}<span v-if="!isRsv"
        >, {{ $config('translates.notifyInTopCorner') }}</span
      >
    </p>

    <v-form v-model="valid" ref="form" @submit.prevent="preSubmit">
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md6 lg4>
          <v-layout row wrap>
            <v-flex xs10 sm8>
              <v-text-field
                v-model="CaseID"
                name="CaseID"
                :label="this.$config('translates.caseNumber')"
                :rules="CaseIDRules"
                mask="  ############"
                id="case"
              ></v-text-field>
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mt-1 mt-3"
              :loading="loading"
              :disabled="loading"
              @click.native="preSubmit"
            >
              {{ $config('translates.search') }}
            </v-btn>
          </v-layout>
          <v-layout row align-center justify-center class="mt-3">
            <div ref="recaptcha" data-size="invisible"></div>
          </v-layout>

          <small>
            {{ $config('translates.youCanCallUs') }}
          </small>
          <v-alert outlined color="error" icon="warning" :value="!!error">
            {{ errorParsed }}
          </v-alert>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { getCaseInfo } from '../api';
export default {
  data() {
    return {
      valid: true,
      CaseID: null,
      CaseIDRules: [(v) => !!v || this.$config('translates.typeCaseNumber')],
      error: null,
      loading: false,
      grecaptcha_id: null,
    };
  },
  mounted() {
    window.onloadCallback = () => {
      this.grecaptcha_id = window.grecaptcha.render(this.$refs.recaptcha, {
        sitekey: this.$config('recaptcha_token'),
        callback: this.submit,
      });
    };

    const urlParams = new URLSearchParams(window.location.search);
    this.CaseID = urlParams.get('caseID');

    //            this.$refs.form.$el.onkeyup = e => {
    //                e.preventDefault()
    //
    //                this.preSubmit()
    //            }
  },
  methods: {
    preSubmit() {
      window.grecaptcha.execute();
    },
    submit(gRecaptchaResponse) {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      let data = {
        CaseID: this.CaseID,
        'g-recaptcha-response': gRecaptchaResponse,
      };

      getCaseInfo(data)
        .then((response) => {
          this.loading = false;
          this.$emit('caseData', response.data);
          window.grecaptcha.reset(this.grecaptcha_id);
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
          window.grecaptcha.reset(this.grecaptcha_id);
        });
    },
  },
  computed: {
    isRsv() {
      return this.$config('title').toString() === 'PCB'.toString();
    },
    errorParsed() {
      switch (this.error) {
        case 'Incorrect captcha value':
          return this.$config('translates.incorrectCaptcha');
        case 'Case not found':
          return this.$config('translates.caseNotFound');
        default:
          return this.$config('translates.500Error');
      }
    },
  },
};
</script>
