import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify';
import VueHead from 'vue-head';
import 'vuetify/dist/vuetify.css';
import Vue2Filters from 'vue2-filters';
import money from 'v-money';
import { config, getConfig } from './config';
import { get } from 'lodash';
Vue.use(money, {
  precision: 0,
  thousands: ' ',
});

Vue.use(config);

Vue.use(Vue2Filters);

import colors from 'vuetify/es5/util/colors';
import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

Vue.use(Vuetify, {
  theme: {
    primary:
      get(colors, getConfig('colors.primary')) ||
      getConfig('colors.primary') ||
      colors.blue.darken1,
    secondary:
      get(colors, getConfig('colors.secondary')) ||
      getConfig('colors.secondary') ||
      colors.shades.white,
  },
});

Vue.use(VueHead);

new Vue({
  el: '#app',
  vuetify: new Vuetify(),
  render: (h) => h(App),
});
