<template>
  <v-app class="responsive-layout">
    <v-main>
      <v-container fluid fill-height class="pa-0">
        <v-layout align-center justify-center>
          <payment-form
            v-if="$config('showNewPaymentForm')"
            :logo="logo"
            :CaseID="queryCaseID"
          />
          <v-flex xs12 sm10 md8 v-else>
            <v-card class="elevation-12">
              <v-toolbar :dark="$config('theme') === 'dark'" height="80px">
                <v-layout align-center>
                  <v-flex>
                    <a href="/" class="mt-1">
                      <img
                        :src="logo"
                        alt="Logo"
                        style="
                          padding-left: 24px;
                          padding-top: 5px;
                          width: 300px;
                        "
                      />
                    </a>
                  </v-flex>
                  <v-spacer></v-spacer>

                  <v-toolbar-title
                    class="hidden-xs-only pr-4"
                    style="
                      text-transform: uppercase;
                      font-weight: 400;
                      font-size: 1.4em;
                    "
                  >
                    {{ $config('translates.onlinePaymentService') }}
                  </v-toolbar-title>
                </v-layout>
              </v-toolbar>
              <v-card-text v-if="e6 === 1">
                <p>
                  {{ $config('translates.youHaveOpportunity') }}
                </p>

                <p v-if="!isRsv">{{ $config('forPaymentNeedResult') }}</p>
              </v-card-text>

              <v-stepper v-model="e6" alt-labels>
                <v-stepper-header>
                  <v-stepper-step step="1" :complete="!!caseData">
                    {{ $config('translates.identification') }}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="2" :complete="confirm">
                    {{ $config('translates.aprovment') }}
                  </v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3" :complete="e6 > 3">
                    {{ $config('translates.payment') }}
                  </v-stepper-step>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content step="1">
                    <step1 @caseData="step2"></step1>
                  </v-stepper-content>
                  <v-stepper-content step="2">
                    <step2
                      :caseData="caseData"
                      @confirm="confirm = true"
                      @cancel="caseData = null"
                    >
                    </step2>
                  </v-stepper-content>
                  <v-stepper-content step="3">
                    <step3
                      :caseData="caseData"
                      v-if="confirm === true"
                      @cancel="confirm = false"
                    ></step3>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              <v-card-actions>
                <v-layout align-center justify-center wrap>
                  <template
                    v-for="(logoSet, index) in $config('provider_logos', [])"
                  >
                    <a
                      :key="index"
                      :href="logoSet.link"
                      v-if="logoSet.link"
                      target="blank"
                      style="height: 40px; display: inline-block"
                      class="mr-4"
                    >
                      <img
                        height="40"
                        :src="provider_logo(logoSet.img)"
                        alt=""
                      />
                    </a>
                    <img
                      :key="index"
                      v-else
                      height="40"
                      :src="provider_logo(logoSet.img)"
                      alt=""
                    />
                  </template>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import step1 from './steps/step1.vue';
import step2 from './steps/step2.vue';
import step3 from './steps/step3.vue';
import PaymentForm from '@/payment/paymentForm';
export default {
  components: {
    PaymentForm,
    step1,
    step2,
    step3,
  },
  data() {
    return {
      caseData: null,
      confirm: false,
      queryCaseID: window.location.pathname.split('/').pop(),
      faviconFolder: this.$config('faviconFolder')
        ? this.$config('faviconFolder')
        : 'ce',
    };
  },
  head: {
    title() {
      return {
        separator: '-',
        complement: this.$config(
          'titleComponents',
          this.$config('translates.onlinePaymentService'),
        ),
        inner: this.$config('title', 'CREDITEXPRESS'),
      };
    },
    meta() {
      return [
        {
          name: 'msapplication-TileColor',
          content: '#ffffff',
        },
        {
          name: 'msapplication-TileImage',
          // eslint-disable-next-line unicorn/prefer-module
          content: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/ms-icon-144x144.png'),
        },
        {
          name: 'theme-color',
          content: '#ffffff',
        },
      ];
    },
    link() {
      return [
        {
          rel: 'apple-touch-icon',
          sizes: '57x57',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-57x57.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '60x60',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-60x60.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '72x72',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-72x72.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '76x76',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-76x76.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '114x114',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-114x114.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '120x120',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-120x120.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '144x144',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-144x144.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '152x152',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-152x152.png'),
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/apple-icon-180x180.png'),
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '192x192',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/android-icon-192x192.png'),
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/favicon-32x32.png'),
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/favicon-96x96.png'),
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          // eslint-disable-next-line unicorn/prefer-module
          href: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/favicon-16x16.png'),
        },
        {
          rel: 'manifest',
          // eslint-disable-next-line unicorn/prefer-module
          type: require('./assets/favicon/' +
            this.$config('favicon', this.faviconFolder) +
            '/manifest.json'),
        },
      ];
    },
  },
  methods: {
    step2(caseData) {
      this.caseData = caseData;
    },

    provider_logo(name) {
      // eslint-disable-next-line unicorn/prefer-module
      return require('./assets/img/' + name);
    },
  },
  computed: {
    isRsv() {
      return this.$config('title').toString() === 'PCB'.toString();
    },
    e6: {
      get() {
        if (!this.caseData) {
          return 1;
        } else if (!this.confirm) {
          return 2;
        } else {
          return 3;
        }
      },
      set() {},
    },
    logo() {
      // eslint-disable-next-line unicorn/prefer-module
      return require('./assets/img/' + this.$config('logo', 'logo_ru.png'));
    },
  },
};
</script>

<style lang="scss">
.responsive-layout {
  @media (max-width: 600px) {
    min-width: 100%;
  }
  @media (min-width: 601px) {
    min-width: 600px;
  }
}
.theme--light .input-group input:disabled {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light .input-group .input-group--text-field__suffix {
  color: rgba(0, 0, 0, 0.87);
}
.tabs__item.tabs__item--active {
  background-color: #b3d4fc;
}
.stepper__label {
  text-transform: uppercase;
}
p {
  font-size: 16px;
}
.rbkmoney-checkout {
  background-color: brown;
}
</style>
