<template>
  <v-card :loading="loading" class="payment-wrap">
    <a href="/" class="mt-1">
      <img :src="logo" alt="Logo" style="padding-top: 5px; width: 280px" />
    </a>
    <template v-if="caseData && !loading">
      <div class="case-info" v-if="caseData">
        <div>
          <span>{{ $config('translates.payment.caseID') }}</span>
          <span class="case-id"
            >{{ caseData.ID }}{{ caseData.CheckDigit }}</span
          >
        </div>
        <p>
          {{ $config('translates.payment.сontract') }}
          {{ caseData.AccountNum }} {{ $config('translates.payment.from') }}
          {{ getDate(caseData.FromDate) }}
        </p>
        <p class="user-name">{{ caseData.FullName }}</p>
      </div>
      <div
        class="payment__title text-center"
        style="margin-bottom: 10px"
        v-if="caseData"
      >
        {{ $config('translates.payment.iPay') }}
      </div>
      <div class="buttons-wrap" v-if="caseData">
        <div
          v-if="caseData.InstallmentInfo"
          :class="{ active: activePayment === 1 }"
          color="primary"
          class="buttons-wrap__item"
          @click="setAmount(1, +caseData.InstallmentInfo.MonthlyPayment)"
        >
          <span>{{ $config('translates.payment.periodicPayment') }}</span>
          <span
            >{{ formateNumber(caseData.InstallmentInfo.MonthlyPayment) }}
            {{ caseData.Currency }}</span
          >
        </div>
        <div
          :class="{ active: activePayment === 2 }"
          color="primary"
          class="buttons-wrap__item"
          @click="setAmount(2, +caseData.ActualAmount)"
        >
          <span>{{ $config('translates.payment.Amount') }}</span>
          <span
            >{{ formateNumber(caseData.ActualAmount) }}
            {{ caseData.Currency }}</span
          >
        </div>
      </div>
      <div class="payment" v-if="caseData">
        <v-form v-model="valid" ref="form" class="pay-sum-form">
          <v-text-field
            ref="paySumInput"
            style="max-width: 200px"
            class="pt-2 pl-3 pr-3 input-group--focused"
            v-model="PaySum"
            @keypress="onlyNumber"
            @input="handleInput"
            name="PaySum"
            :placeholder="this.$config('translates.typeSum')"
            :rules="PaySumRules"
            :suffix="caseData.Currency"
          ></v-text-field>
          <v-btn
            color="primary"
            class="pay-btn"
            @click="pay"
            v-if="!liqPayData"
            >{{ $config('translates.payment.createPayment') }}</v-btn
          >
        </v-form>
      </div>
      <form
        method="POST"
        action="https://www.liqpay.ua/api/3/checkout"
        accept-charset="utf-8"
        v-if="liqPayData"
      >
        <input type="hidden" v-model="liqPayData.data" name="data" />
        <input type="hidden" v-model="liqPayData.signature" name="signature" />
        <div class="btn-wrap">
          <v-btn type="submit" class="pay-btn liq-pay-btn"
            >{{ $config('translates.payment.pay') }} {{ PaySum }}
            {{ caseData.Currency }}</v-btn
          >
        </div>
      </form>
      <v-alert
        outlined
        color="error"
        icon="warning"
        :value="!!error"
        style="margin-top: 10px"
      >
        {{ errorParsed }}
      </v-alert>
      <div
        class="other-debts"
        v-if="caseData && caseData.RelatedCases.length > 0"
      >
        <span class="other-debts__title">{{
          $config('translates.payment.otherDebt')
        }}</span>
        <table class="other-debts__table payment-table">
          <thead>
            <tr>
              <th>{{ $config('translates.payment.caseID') }}</th>
              <th>{{ $config('translates.payment.сontractNum') }}</th>
              <th>{{ $config('translates.payment.date') }}</th>
              <th>{{ $config('translates.payment.sum') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in caseData.RelatedCases" :key="item.ID">
              <td>
                <a :href="getPaymentLink(`${item.ID}${item.CheckDigit}`)"
                  >{{ item.ID }}{{ item.CheckDigit }}</a
                >
              </td>
              <td>{{ item.AccountNum }}</td>
              <td>{{ getDate(item.FromDate) }}</td>
              <td>
                {{ formateNumber(item.ActualAmount) }} {{ item.Currency }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="!loading">
      <v-form
        v-model="valid"
        ref="form"
        @submit.prevent="preSubmit"
        class="case-form-wrap"
      >
        <div class="case-form">
          <v-text-field
            v-model="CaseID"
            name="CaseID"
            :label="this.$config('translates.caseNumber')"
            :rules="CaseIDRules"
            mask="  ############"
            id="case"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="mt-1 mt-3"
            :loading="loading"
            :disabled="loading"
            @click.native="preSubmit('native')"
          >
            {{ $config('translates.search') }}
          </v-btn>
        </div>
        <p class="case-form__description">
          {{ $config('translates.youCanCallUs') }}
        </p>
        <v-alert outlined color="error" icon="warning" :value="!!error">
          {{ errorParsed }}
        </v-alert>
      </v-form>
    </template>
    <v-layout row align-center justify-center class="mt-3">
      <div ref="recaptcha" data-size="invisible"></div>
    </v-layout>
    <div
      class="payment-contacts"
      v-html="$config('translates.payment.contact')"
    ></div>
  </v-card>
</template>

<script>
import { getCaseInfo, getLiqPay } from '../api';
// import axios from 'axios';
import moment from 'moment';

export default {
  props: ['logo'],
  data() {
    return {
      valid: true,
      CaseID: null,
      CaseIDFromUrl: window.location.pathname.split('/').pop(),
      caseData: null,
      PaySum: null,
      CaseIDRules: [(v) => !!v || this.$config('translates.typeCaseNumber')],
      error: null,
      loading: true,
      grecaptcha_id: null,
      activePayment: null,
      PaySumRules: [],
      liqPayData: null,
    };
  },
  mounted() {
    window.onloadCallback = () => {
      this.grecaptcha_id = window.grecaptcha.render(this.$refs.recaptcha, {
        sitekey: this.$config('recaptcha_token'),
        callback: this.submit,
      });
      if (this.CaseIDFromUrl) {
        this.preSubmit();
      } else {
        this.loading = false;
      }
    };
  },
  methods: {
    onlyNumber($event) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    handleInput(value) {
      this.PaySum = value
        .split(' ')
        .join('')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    preSubmit(isNative) {
      if (isNative) {
        history.pushState({}, null, this.CaseID);
      }
      window.grecaptcha.execute();
    },
    submit(gRecaptchaResponse) {
      this.loading = true;
      this.error = null;
      let data = {
        CaseID: this.CaseID || this.CaseIDFromUrl,
        Extension: 'online-payments',
        'g-recaptcha-response': gRecaptchaResponse,
      };

      getCaseInfo(data)
        .then((response) => {
          this.loading = false;
          this.caseData = response.data;
          this.PaySum = this.caseData.InstallmentInfo
            ? this.formateNumber(this.caseData.InstallmentInfo.MonthlyPayment)
            : this.formateNumber(this.caseData.ActualAmount);
          this.PaySumRules = [
            () =>
              this.PaySumInt >= this.MinAmountToPay ||
              `${this.$config(
                'translates.minAmount',
              )} ${this.currencyFilterWithDecimals(this.MinAmountToPay)} ${
                this.caseData.Currency
              }`,
            () =>
              this.PaySumInt < 1_000_000 ||
              this.$config('translates.incorrectAmount'),
          ];
          this.activePayment = this.caseData.InstallmentInfo ? 1 : 2;
          window.grecaptcha.reset(this.grecaptcha_id);
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.message;
          window.grecaptcha.reset(this.grecaptcha_id);
        });
    },
    setAmount(id, value) {
      this.activePayment = id;
      this.PaySum = this.formateNumber(value);
    },
    currencyFilter(value) {
      return this.$options.filters.currency(value, '', 0, {
        thousandsSeparator: ' ',
        decimalSeparator: '.',
      });
    },
    currencyFilterWithDecimals(value) {
      return this.$options.filters.currency(value, '', 2, {
        thousandsSeparator: ' ',
        decimalSeparator: '.',
      });
    },
    pay() {
      if (!this.$refs.form.validate()) return;
      let data = {
        command: 'generate-invoice',
        sum: this.PaySum.split(' ').join(''),
        account: +`${this.caseData.ID}${this.caseData.CheckDigit}`,
      };
      getLiqPay(data).then((response) => {
        this.liqPayData = response.data;
      });
    },
    getPaymentLink(id) {
      return window.location.origin + '/' + id;
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    formateNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
  watch: {
    PaySum() {
      this.$nextTick(() => {
        this.valid = this.$refs.paySumInput.valid;
      });
      this.liqPayData = null;
    },
  },
  computed: {
    errorParsed() {
      switch (this.error) {
        case 'Incorrect captcha value':
          return this.$config('translates.incorrectCaptcha');
        case 'Case not found':
          return this.$config('translates.caseNotFound');
        default:
          return this.$config('translates.500Error');
      }
    },
    currentProvider() {
      return this.caseData.PaymentProvider[+this.activeTab];
    },
    MinAmountToPay() {
      return 1;
    },
    PaySumInt() {
      return ('' + this.PaySum).replace(/\s/g, '');
    },
  },
};
</script>

<style lang="scss">
.payment-wrap {
  max-width: 500px !important;
  width: 100%;
  padding: 0 20px 10px;
  margin: 20px 0;
}
.case-info {
  width: 100%;
  background: #efefef;
  margin: 0 auto 20px;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  .case-id {
    font-weight: bold;
    font-size: 34px;
  }
  div + div {
    margin-top: 10px;
  }
  span {
    display: block;
    &:first-child {
      font-weight: bold;
    }
  }
}
.user-name {
  text-align: center;
  font-weight: bold;
  margin-bottom: 0 !important;
}
.payment-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  box-sizing: border-box;
  th {
    text-align: center;
    font-weight: normal;
  }
  tr {
    border-bottom: 1px dotted black;
    &.active {
      background: #1976d2;
      color: #fff;
    }
  }
  td {
    padding: 5px 3px;
  }
}
.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  input {
    text-align: center;
    font-size: 20px;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
  }
  &__buttons {
    button + button {
      margin-left: 20px;
    }
  }
}
.buttons-wrap {
  //display: grid;
  //grid-template-columns: repeat(2, 1fr);
  //grid-column-gap: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  &__item {
    max-width: 50%;
    width: 100%;
    margin: 0 10px;
    background: #efefef;
    border-radius: 6px;
    padding: 10px;
    color: #000;
    text-align: center;
    cursor: pointer;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
      0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    &.active {
      background: #1976d2;
      color: #fff;
    }
    span {
      font-size: 14px;
      &:last-child {
        font-size: 18px;
        font-weight: bold;
      }
      display: block;
    }
  }
}
.pay-btn {
  width: 220px;
  height: 40px !important;
  margin-top: 10px;
  color: #fff !important;
}
.liq-pay-btn {
  background: linear-gradient(
    135deg,
    #9fdb57,
    #71ca5e 33.7%,
    #36b98f 68.75%,
    #1fadc3
  );
}
.text-center {
  text-align: center;
}
.other-debts {
  margin-top: 40px;
  &__title {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &__table {
    text-align: center;
    font-size: 14px;
  }
}
.payment-contacts {
  display: block;
  margin-top: 30px;
  font-size: 12px !important;
  p {
    font-size: 12px !important;
  }
  &__title {
    display: block;
    font-weight: bold;
  }
  &__item {
    margin-bottom: 10px;
    text-align: center;
  }
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.case-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &-wrap {
    width: 100%;
    margin-bottom: 40px;
    small {
      line-height: 100%;
    }
  }
  .v-input {
    width: 100%;
    max-width: 60%;
    input {
      text-align: center;
    }
    .v-messages__message {
      text-align: center;
    }
  }
  .v-text-field .v-label {
    left: 50% !important;
    transform: translateX(-50%);
  }
  .v-text-field .v-label--active {
    transform: translateY(-18px) translateX(-50%) scale(0.75);
    transform-origin: top center !important;
  }
  &__description {
    text-align: center;
    margin-top: 10px;
    font-size: 12px;
    line-height: 100%;
  }
}
.pay-sum-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
