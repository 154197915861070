import axios from 'axios';
import { getConfig } from './config';

let instance = axios.create({
  baseURL: getConfig('link'),
  timeout: 60_000,
});

export const getCaseInfo = (data) => instance.post('case', data);

export const getCasePaymentLink = (data) => instance.post('payment', data);

export const getLiqPay = (data) => instance.post('liq-pay', data);

//  "link": "https://online-api.ua.creditexpress.com",
